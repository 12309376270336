<template>
  <b-modal
      id="confirm-room-deletion"
      centered
      hide-header
      hide-footer
      @hidden="confirmedDelete(null)"
    >
      <div class="header">
        <div class="header-content">
            <p>Confirmar exclusão da sala </p>
            <v-close class="close-svg" @click="confirmedDelete(null)" />
        </div>
      </div>
      <div class="body">
        <v-error />
        <p>Atenção! A remoção da sala/setor <span>"{{ room && room.name }}"</span> pode fazer com que alguns agendamentos fiquem sem sala/setor.
        <br><br>
        Ao excluir a sala/setor, não deve ter nenhum agendamento vinculado a ela. Deseja continuar?</p>
      </div>

      <div class="actions">
        <b-button
          class="cancel-button"
          variant="outline-danger"
          @click="confirmedDelete(null)"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="confirmedDelete(room)"
        >
          Confirmar
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import Error from '@/assets/icons/error.svg'

export default {
  name: 'ConfirmRoomDeletion',
  components: {
    'v-close': Close,
    'v-error': Error,
  },
  props: {
    room: Object,
    confirmedDelete: Function,
  },
}
</script>

<style lang="scss">
  #confirm-room-deletion {
    .modal-content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 675px;
      text-align: center;

      .modal-body {
        padding: 0;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);

        .header {
          border-bottom: 1px solid var(--neutral-200);

          .header-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin: 24px;

              .close-svg {
                  height: 24px;
                  width: 24px;
                  fill: var(--neutral-500);
              }
          }
        }
        .body {
          padding: 60px;
          padding-bottom: 0;
          color: var(--type-active);
          font-size: 18px;
          font-weight: 600;

          p {
            margin-top: 40px;
            margin-bottom: 40px;
          }

          span {
            color: var(--blue-500);
          }
        }

        .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 60px;
          padding-top: 0;

          button {
            margin-left: 10px !important;
          }

          .cancel-button {
            font-weight: bold !important;
            border-color: transparent !important;
          }
        }
      }
    }
  }
</style>
