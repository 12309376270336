<template>
    <div :class="{ ellipsis: ellipsis, 'option-disabled': procedure?.$isDisabled}">
      <div :class="`procedure-prefix ${procedure?.$isDisabled ? 'option-disabled' : ''}`">
        <span v-if="!hideIcon" class="icon-box">
          <v-procedure-icon v-if="procedure?.type === 'PROCEDURE'" :class="`icon stroke procedure-icon  ${procedure.$isDisabled ? 'option-disabled' : ''}`" />
          <v-telemedicine-icon v-if="procedure?.type === 'TELEMEDICINE'" :class="`icon stroke procedure-icon  ${procedure.$isDisabled ? 'option-disabled' : ''}`" />
          <v-appointment-icon v-if="procedure?.type === 'APPOINTMENT'" :class="`icon stroke procedure-icon  ${procedure.$isDisabled ? 'option-disabled' : ''}`" />
          <v-return-icon v-if="procedure?.type === 'RETURN'" :class="`icon stroke procedure-icon  ${procedure.$isDisabled ? 'option-disabled' : ''}`" />
          <v-exam-icon v-if="procedure?.type === 'EXAM'" :class="`icon stroke procedure-icon  ${procedure.$isDisabled ? 'option-disabled' : ''}`" />
          <v-surgical-icon v-if="procedure?.type === 'SURGICAL'" :class="`icon stroke surgery-icon ${procedure.$isDisabled ? 'option-disabled' : ''}`" />
        </span>
        <span v-if="!hideType" :class="{ 'line-through': procedure?.deleted_at }">{{ parseProcedureType(procedure.type) }}</span>
        <div v-if="!hideType" class="procedure-pipe" />
      </div>
      <span v-if="!hideName || procedure?.disabled" :class="{ 'line-through': procedure.deleted_at }">
        {{ procedure?.name }}{{ suffix }}
        <span v-if="more && more.length - 1 > 0" :id="`more_${procedure?.id}`" class="more">+ {{ more.length - 1 }}</span>
        <b-tooltip v-if="more && more.length - 1 > 0" :target="`more_${procedure?.id}`" placement="bottom" triggers="hover">
          <div class="tooltip-more" v-for="moreProcedure in more.filter(el => el.id !== procedure?.id)" :key="moreProcedure.id">
            <div :class="`procedure-prefix ${procedure?.$isDisabled ? 'option-disabled' : ''}`">
              <span class="icon-box">
                <v-procedure-icon v-if="moreProcedure?.type === 'PROCEDURE'" :class="`icon stroke procedure-icon  ${procedure?.$isDisabled ? 'option-disabled' : ''}`" />
                <v-telemedicine-icon v-if="moreProcedure?.type === 'TELEMEDICINE'" :class="`icon stroke procedure-icon  ${procedure?.$isDisabled ? 'option-disabled' : ''}`" />
                <v-appointment-icon v-if="moreProcedure?.type === 'APPOINTMENT'" :class="`icon stroke procedure-icon  ${procedure?.$isDisabled ? 'option-disabled' : ''}`" />
                <v-return-icon v-if="moreProcedure?.type === 'RETURN'" :class="`icon stroke procedure-icon  ${procedure?.$isDisabled ? 'option-disabled' : ''}`" />
                <v-exam-icon v-if="moreProcedure?.type === 'EXAM'" :class="`icon stroke procedure-icon  ${procedure?.$isDisabled ? 'option-disabled' : ''}`" />
                <v-surgical-icon v-if="moreProcedure?.type === 'SURGICAL'" :class="`icon stroke surgery-icon  ${procedure?.$isDisabled ? 'option-disabled' : ''}`" />
              </span>
              <span :class="{ 'line-through': moreProcedure?.deleted_at }">{{ parseProcedureType(moreProcedure?.type) }}</span>
              <div class="procedure-pipe" />
            </div>
            <span :class="{ 'line-through': moreProcedure?.deleted_at }">{{ moreProcedure?.name }}</span>
          </div>
        </b-tooltip>
      </span>
    </div>
</template>
<script>
import Procedure from '@/assets/icons/procedure.svg'
import Telemedicine from '@/assets/icons/telemedicine.svg'
import Appointment from '@/assets/icons/appointment.svg'
import Return from '@/assets/icons/return.svg'
import Exam from '@/assets/icons/exam.svg'
import Surgical from '@/assets/icons/surgical.svg'

export default {
  props: {
    procedure: { type: Object, require: true },
    more: Array,
    ellipsis: Boolean,
    suffix: String,
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideType: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    'v-procedure-icon': Procedure,
    'v-telemedicine-icon': Telemedicine,
    'v-appointment-icon': Appointment,
    'v-return-icon': Return,
    'v-exam-icon': Exam,
    'v-surgical-icon': Surgical
  },
  methods: {
    parseProcedureType(type) {
      switch(type) {
        case 'PROCEDURE':
          return 'Procedimento';
        case 'TELEMEDICINE':
          return 'Telemedicina';
        case 'APPOINTMENT':
          return 'Presencial';
        case 'RETURN':
          return 'Retorno';
        case 'EXAM':
          return 'Exame';
        case 'SURGICAL':
          return 'Centro Cirúrgico';
        default:
          return type;
      }
    },
  }
}
</script>
<style lang="scss" scoped>

  .procedure-prefix {
    display: inline-flex;
    align-items: center;
    color: var(--blue-500);
    font-weight: 600 !important;
    font-size: 16px !important;
  }
  .attendance-selected {
    display: inline-flex;
    align-items: center;
    color: var(--type-active);
  }
  .procedure-pipe {
    height: 15px;
    width: 2px;
    background-color: var(--blue-500);
    margin: 0 5px;
    border-radius: 50%;
  }
  .procedure-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    fill: var(--blue-500);
  }
  .surgery-icon { 
    margin: 0 5px 2px 0;
    transform: scale(0.8); 
  }
  .more {
    background-color: var(--neutral-300);
    padding: 2px 10px;
    border-radius: 30px;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 12px;
    color: var(--neutral-700);
    cursor: default;
  }
  .tooltip-more {
    text-align: left;
    padding: 5px 0;
  }
  .line-through {
    text-decoration: line-through;
  }
  .ellipsis {
    width: 99%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .option-disabled {
    color: var(--neutral-500);
    fill: var(--neutral-500);
    cursor: default;
  }
</style>
