<template>
  <div>
    <b-table-simple striped>
      <tbody>
        <tr class="tr-header">
          <th>Sala ou setor</th>
          <th>Tipo de sala</th>
          <th>Procedimentos realizados</th>
          <th></th>
        </tr>

        <tr v-for="room in clinicRooms" :key="room.id" class="tr-header">
          <td>{{ room.name }}</td>
          <td>{{ room.type }}</td>
          <td>
            <v-procedure-line
              v-if="room.items.length > 0"
              :procedure="room.items[0]"
              :more="room.items"
            />
          </td>
          <td class="text-right p-3">
            <div v-if="userHasPermission('CcSa3') || userHasPermission('CcSal4')">
              <div class="actions">
                <Icon tooltip="Editar">
                <Edit class="icon"
                  v-can="'CcSa3'"
                  variant="link"
                  @click="editClinicRoom(room)"
                />
                </Icon>
                <Icon tooltip="Remover">
                <Delete class="icon"
                  v-can="'CcSal4'"
                  variant="link remove mb-0"
                  @click="removeClinicRoom(room)"
                />
              </Icon>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
    <v-confirm-room-deletion
      :room="roomToDelete"
      :confirmedDelete="confirmedDelete"/>
  </div>
</template>

<script>

import { userHasPermission } from '@/utils/localStorageManager'
import ProcedureLine from '@/components/General/ProcedureLine'
import ConfirmRoomDeletion from '@/components/Room/ConfirmRoomDeletion'

export default {
  components: {
    Icon: () => import('@/components/General/Icon'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    'v-procedure-line': ProcedureLine,
    'v-confirm-room-deletion': ConfirmRoomDeletion,
  },
  props: {
    clinicRooms: { type: Array, required: true },
    onUpdate: Function,
    onDelete: Function,
  },
  data () {
    return {
      roomToDelete: null,
    }
  },
  methods: {
    userHasPermission,
    editClinicRoom(clinicRoom) {
      this.onUpdate(clinicRoom)
    },
    confirmedDelete(room) {
      room && this.onDelete && this.onDelete(room)
      this.roomToDelete = null;
      this.$bvModal.hide('confirm-room-deletion');
    },
    removeClinicRoom(clinicRoom) {
      this.roomToDelete = clinicRoom
      this.$bvModal.show('confirm-room-deletion')
    },
  }
}
</script>

<style lang="scss" scoped>

.table th, .table td {
    padding: 0.75rem 0;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    /* border: none; */
}

.tr-header {

  th:first-child {
    padding-left: 16px;
  }

  .table th, .table td {
    padding: 0;
    vertical-align: middle;
    border-top: none;
  }

  td:first-child {
    padding: 16px 50px 16px 16px !important;
    max-width: 25ch;
  }
}

th {
  font-size: 14px;
  color: var(--type-active);
}
.td {
  padding: 0.75rem !important;
}
.icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  cursor: pointer; 
}
.option {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
}
.toggle-button {
  margin-left: 15px;
}

.actions {
  justify-content: right;
  display: flex;
}
</style>
